<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row id="main1" class="maintText__row">
            <b-col cols="12" class="pb-5 side-gap">
                <h2 style="mainText__mainName--maintxtx">
                   Mások mércéjével méred magad?
                </h2>
                <p class="hashText">- Így hat rád!</p>
            </b-col>
        </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                A közösségi média már szinte minden ember életében jelen van. Akár aktív alkotói, akár passzív nézői vagyunk az eseményeknek, kétségtelenül hat ránk és a világról, illetve önmagunkról alkotott képünkre. Az emberek az életük megjelenítésének módját saját maguk választhatják meg, akár a valóság nélkülözésével. Az azonban, hogy mi számít valótlannak ezen a platformon, nem fekete-fehér. Hol húzzuk meg a határt? A kitalált nyaralás, a nem létező párkapcsolat, vagy éppen az átszerkesztett kép és egy erőltetetten vidám pillanat az, ami fennakad a rostán?</p>

                <p class="text">
                A jelenség fontos hozadéka az emberek egymásra és magukra tett hatása, a társas összehasonlítás. A társas összehasonlítás egy olyan szociálpszichológiai jelenség, mely szerint a saját értékünket, kompetenciánkat vagy más, szubjektív jellemzőinket úgy érezzük helyesnek felmérni, ha másokkal vetjük össze magunkat. Ezzel egy folyamatos, kimondatlan versenyt hozunk létre, melyben valaki mindig veszít. A nyertes megerősíti az énképét, míg vesztes ideális esetben motiválódik a fejlődésre. Nincs ez másként a közösségi média felületeken sem, egy faktor azonban megváltozott: egyszerre több száz ismerőssel, több ezer hírességgel és akár több millió ismeretlennel „mérkőzhetünk” meg az agyunk egy szórakozásnak álcázott ketrecharcában. </p>

                <p class="text">
                A valóság az, hogy erre igényünk van, a mérték azonban korántsem mindegy. Összeszedtünk tehát néhány érdekes jelenséget a közösségi média lehetséges hatásairól az aktív felhasználókra. </p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/egyes.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Tökéletesség illúziója: </b> Fontos azt tudatosítani magunkban, hogy a közösségi médiára kitett posztok csupán egy töredéket mutatnak be az életünkről, és hogy a látszólag tökéletes életet élő emberek valójában ugyanolyan kihívásokkal küzdenek, mint bárki más.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Örömszerző ördögi kör: </b> Az előző pontból következik egyfajta öngerjesztő spirál. A közösségi médián kontrollálhatjuk azt, hogy mit lássanak az életünkből, és ezt a tökéletesre mázolt képet nehezen tudjuk elengedni. Sőt, ha bíztatva vagyunk kommentek és like-ok vagy más reakciók által, akkor az élvezeti cikkek fogyasztásához hasonlóan egyre többre és többre van szükségünk belőlük ahhoz, hogy örömünket leljük benne. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Szubjektív boldogság:</b> mivel a boldogság mértéke a legtöbb objektív tényezőtől, mint a pénz, siker vagy a barátok ironikus módon független, marad itt is a társas összehasonlítás. Ha pedig nem érezzük magunkénak a saját közösségi médián alkotott képünket, akkor ez alááshatja azt, hogy értékelni tudjuk a saját életünket. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Örök verseny önmagunkkal: </b> az emberek hajlamosak eltúlozni a boldogságot és a sikerességet, amit mások számára mutatnak a közösségi médián, és ennek következtében magukat is állandó boldogságra és sikerre - vagy legalábbis annak látszatára - kényszerítik. Mint az előző pontban is megjelent, a boldogság elvárása önmagunktól épp az ellenkezőjét érheti el.</p>
                </li>
              </ul>
            </p>
            <p class="text">
                Ahhoz, hogy csökkenthessük az összehasonlítás miatti stresszt és elégedetlenséget a közösségi médiában, fontos, hogy megtanuljuk néhol elhagyni az összehasonlítás alapját, és inkább a saját életünkre koncentráljunk. Meg kell tanulnunk elfogadni önmagukat és a saját életüket olyannak, amilyen, és tudnunk kell, hogy minden ember mögött van egy egyedi élettörténet, kihívásokkal és sikerekkel. 
            </p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Mások mércéjével méred magad?", 
        desc: "A közösségi média már szinte minden ember életében jelen van. Akár aktív alkotói, akár passzív nézői vagyunk az eseményeknek, kétségtelenül hat ránk és a világról, illetve önmagunkról alkotott képünkre.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Mások mércéjével méred magad?",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A közösségi média már szinte minden ember életében jelen van. Akár aktív alkotói, akár passzív nézői vagyunk az eseményeknek, kétségtelenül hat ránk és a világról, illetve önmagunkról alkotott képünkre."        
    })
  }
}
</script>