<template>
    <b-container fluid="xl" class="mainText py-0">
        <b-row class="maintText__row">
            <b-col cols="12">
                <div class="overflow-hidden divider-container">
                    <img class=" section-divider m-auto" src="@/assets/images/main/divider-white.svg"/>
                </div>
                <h2>Ezek is érdekelhetnek</h2>
            </b-col>
            <b-col cols="12" lg="6" class="pt-5 d-fle flex-column" v-for="(element, i) in result" :key="i">
                <img :src="'images/'+element.img" class="w-100 margin-top-sm-44 cover-img"/>
                <h2 class="text-left pb-3">
                    {{ element.title }}
                </h2>
                <p class="hashText text-left">{{ element.subtitle }}</p>
                <div class="maintText__txt-content">
                    <p class="text m-0">
                        {{ element.desc }}
                    </p>
                </div>
                <a :href="element.link" class="more-btn mt-3 d-block">Elolvasom <img src="@/assets/images/icons/black-arrow-right.svg" class="ml-1"></a>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import articles from '../js/articles-data.json'

export default {
  data() {
    return {
      items: [],
      result: []
    }
  },
  props:{
    isFirst: Boolean
  },
  mounted(){
    this.items = articles.articles;

    if(this.isFirst === true){
        this.items = (Object.entries(this.items).reverse().slice(3,5));
        this.result.push(this.items[0][1]);
        this.result.push(this.items[1][1]);
    }else{
        this.items = (Object.entries(this.items).reverse().slice(0,2));
        this.result.push(this.items[0][1]);
        this.result.push(this.items[1][1]);
    }
  }
}
</script>
